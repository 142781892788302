<template>
	<div
		v-if="type == 'ADDEXTENSION' || (type == 'NEW' && this.$store.getters.getBasicItemSelected.length > 0)"
		class="section-aside"
		:class="{ open: openSideBar }"
	>
		<!-- v-bind:style="{ top: computedTop }"  top 속성 제거 @0328 -->
		<div>
			<div
				class="aside-header"
				v-if="this.$store.getters.getBasicItemSelected.length > 0"
				@click="
					() => {
						this.openSideBar = '';
					}
				"
			>
				<!--membership-->
				<p class="description">결제 금액</p>
				<h3 class="heading-2-b">
					{{ serviceNameDisplay(this.$store.getters.getBasicItemSelected[0]) }}
				</h3>
				<ul class="description">
					<li class="txt" v-if="this.$store.getters.getBasicItemSelected[0].usePeriod > 0">
						기간 {{ this.$store.getters.getBasicItemSelected[0].usePeriod }}개월
					</li>
					<li class="txt" v-if="this.$store.getters.getBasicItemSelected[0].userCnt > 0">
						멤버 {{ this.$store.getters.getBasicItemSelected[0].userCnt }}인
					</li>
					<li class="txt" v-if="this.$store.getters.getTotQuota > 0">용량 {{ this.$store.getters.getTotQuota }}GB</li>
				</ul>
			</div>

			<!--@scroll area-->
			<div class="contents-wrap">
				<!--@order item-->
				<div class="contents" v-for="item in this.$store.getters.getBasicItemSelected" :key="item.cd">
					<table v-if="item.usePeriod > 0" class="summary">
						<tbody>
							<tr v-if="item.defaultFeePolicyModel.usePolicy">
								<td class="tit">
									<span class="txt">{{ item.usePeriod }} 개월 기본료</span>
								</td>
								<td class="amount">
									<span class="txt"
										>{{ item.defaultFeePolicyModel.defaultUserNum }}명 /
										{{ item.defaultFeePolicyModel.defaultVolume }}GB</span
									>
								</td>
								<td class="price">
									<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
									<span
										class=""
										v-if="item.defaultFeeTotDiscount < 0"
										v-on:click="toggleFold('base', 'defaultFee', item.id)"
									>
										<button v-if="item.defaultFeeDetailFold == false" class="icon" title="접기">
											<i class="ic-16 ic-fold"></i>
										</button>
										<button v-if="item.defaultFeeDetailFold == true" class="icon" title="펼치기">
											<i class="ic-16 ic-unfold"></i>
										</button>
									</span>
								</td>
							</tr>
							<tr v-if="item.defaultFeeDetailFold == false">
								<td colspan="3">
									<div class="highlight">
										<table class="">
											<tbody>
												<tr>
													<td class="tit">
														<span class="txt">단가</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeePolicyModel.price | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">사용기간</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.usePeriod }} 개월 </span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">정가</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotNormalPrice | currency }}원</span>
													</td>
												</tr>
												<tr class="discount">
													<td class="tit">
														<span class="txt">할인 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotDiscount | currency }}원</span>
													</td>
												</tr>
												<tr class="balance">
													<td class="tit">
														<span class="txt">최종 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr v-if="item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0">
								<td class="tit"><span class="txt">사용자 추가</span></td>
								<td class="amount">
									<span class="txt"
										>{{ item.addUserCnt }}
										<template
											v-if="getTotQuota(item.addUserCnt, item.addUserIntervalPolicyModel.defaultVolumeForAddUser) > 0"
										>
											/
											{{ getTotQuota(item.addUserCnt, item.addUserIntervalPolicyModel.defaultVolumeForAddUser) }}GB
										</template></span
									>
								</td>
								<td class="price">
									<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								v-if="
									item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0 && item.addUserTotNormalPrice > 0
								"
								class="mini"
							>
								<td class="price" colspan="3">
									<span class="txt">
										<del class="text-through">{{ item.addUserTotNormalPrice | currency }}원</del>
									</span>
									<button class="line round x-mini" v-on:click="openModal(item)">상세</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeePolicyModel.usePolicy">
								<td class="tit"><span class="txt">설치비</span></td>
								<!-- 해당 부분에 value(1개)는 원복 필요할 수도 있음 -->
								<td class="amount"><span class="txt">1</span></td>
								<td class="price">
									<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
									<span
										class=""
										v-if="item.membershipFeeDiscount < 0"
										v-on:click="toggleFold('base', 'membershipFee', item.id)"
									>
										<button v-if="item.membershipFeeDetailFold == false" class="icon" title="접기">
											<i class="ic-16 ic-fold"></i>
										</button>
										<button v-if="item.membershipFeeDetailFold == true" class="icon" title="펼치기">
											<i class="ic-16 ic-unfold"></i>
										</button>
									</span>
								</td>
							</tr>
							<tr v-if="item.membershipFeeDetailFold == false">
								<td colspan="3">
									<div class="highlight">
										<table class="">
											<tbody>
												<tr>
													<td class="tit">
														<span class="txt">정가</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeNormalPrice | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">할인 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeDiscount | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">최종 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr v-for="row in item.optionItemSelected" :key="row.id">
								<td class="tit">
									<span class="txt">{{ row.displaySectionModel.displayName }}</span>
								</td>
								<td class="amount">
									<span class="txt">{{ row.orderCount }}</span>
								</td>
								<td class="price">
									<span class="txt">{{ row.finalPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--//order item-->

				<!--@order item-->
				<div class="contents" v-for="(item, index) in this.$store.getters.getAddonItemSelected" :key="index">
					<h4 class="">
						<i v-if="item.name.indexOf('경리회계') > -1" class="ic-24 ic-accounting"></i>
						<i v-if="item.name == '경비처리(무증빙)'" class="ic-24 ic-bizplay"></i>
						<i v-if="item.name == 'TMSe 장비일체형 S'" class="ic-24 ic-tmse-s"></i>
						<i v-if="item.name == 'TMSe 장비일체형 E'" class="ic-24 ic-tmse-e"></i>
						<i v-if="item.name == 'TMSe 장비일체형 T'" class="ic-24 ic-tmse-t"></i>
						<span class="tit">{{ item.name }}</span>
						<span v-if="item.freeTrialPolicyModel.usePolicy == true" class="com_tag_badge">
							<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }}개월 무료 체험</span>
						</span>
					</h4>
					<table class="summary">
						<tbody>
							<tr v-if="item.defaultFeePolicyModel.usePolicy">
								<td class="tit" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
									<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }} 개월 기본료</span>
								</td>
								<td class="tit" v-else>
									<span class="txt">{{ item.usePeriod }} 개월 기본료</span>
								</td>
								<td class="amount">
									<span class="txt">{{ item.defaultFeePolicyModel.defaultUserNum }}</span>
								</td>
								<td class="price">
									<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
									<span
										class=""
										v-if="item.defaultFeeTotDiscount > 0"
										v-on:click="toggleFold('addon', 'defaultFee', item.id)"
									>
										<button v-if="item.defaultFeeDetailFold == false" class="icon" title="접기">
											<i class="ic-16 ic-fold"></i>
										</button>
										<button v-if="item.defaultFeeDetailFold == true" class="icon" title="펼치기">
											<i class="ic-16 ic-unfold"></i>
										</button>
									</span>
								</td>
							</tr>

							<tr v-if="item.defaultFeeDetailFold == false">
								<td colspan="3">
									<div class="highlight">
										<table class="">
											<tbody>
												<tr>
													<td class="tit">
														<span class="txt">단가</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeePolicyModel.price | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">사용기간</span>
													</td>
													<td class="amount"></td>

													<td class="price" v-if="item.freeTrialPolicyModel && item.freeTrialPolicyModel.usePolicy">
														<span class="txt">{{ item.freeTrialPolicyModel.usagePeriod }} 개월 </span>
													</td>
													<td class="price" v-else>
														<span class="txt">{{ item.usePeriod }} 개월 </span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">정가</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotNormalPrice | currency }}원</span>
													</td>
												</tr>
												<tr class="discount">
													<td class="tit">
														<span class="txt">할인 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotDiscount | currency }}원</span>
													</td>
												</tr>
												<tr class="balance">
													<td class="tit">
														<span class="txt">최종 금액</span>
													</td>
													<td class="amount"></td>
													<td class="price">
														<span class="txt">{{ item.defaultFeeTotDiscountPrice | currency }}원</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr v-if="item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0">
								<td class="tit"><span class="txt">사용자 추가</span></td>
								<td class="amount">
									<span class="txt">{{ item.addUserCnt }}</span>
								</td>
								<td class="price">
									<span class="txt">{{ item.addUserTotDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr
								v-if="
									item.addUserIntervalPolicyModel.usePolicy && item.addUserCnt > 0 && item.addUserTotNormalPrice > 0
								"
								class="mini"
							>
								<td class="price" colspan="3">
									<span class="txt"
										><del class="text-through">{{ item.addUserTotNormalPrice | currency }}원</del></span
									><button class="line round x-mini" v-on:click="openModal(item)">상세</button>
								</td>
							</tr>
							<tr v-if="item.membershipFeePolicyModel.usePolicy">
								<td class="tit"><span class="txt">설치비</span></td>
								<!-- 해당 부분에 value(1개)는 원복 필요할 수도 있음 -->
								<td class="amount"><span class="txt">1</span></td>
								<td class="price">
									<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
									<span
										class=""
										v-if="item.membershipFeeDiscount < 0"
										v-on:click="toggleFold('addon', 'membershipFee', item.id)"
									>
										<button v-if="item.membershipFeeDetailFold == false" class="icon" title="접기">
											<i class="ic-16 ic-fold"></i>
										</button>
										<button v-if="item.membershipFeeDetailFold == true" class="icon" title="펼치기">
											<i class="ic-16 ic-unfold"></i>
										</button>
									</span>
								</td>
							</tr>
							<tr v-if="item.membershipFeeDetailFold == false">
								<td colspan="3">
									<div class="highlight">
										<table class="">
											<tbody>
												<tr>
													<td class="tit">
														<span class="txt">정가</span>
													</td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeNormalPrice | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">할인 금액</span>
													</td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeDiscount | currency }}원</span>
													</td>
												</tr>
												<tr>
													<td class="tit">
														<span class="txt">최종 금액</span>
													</td>
													<td class="price">
														<span class="txt">{{ item.membershipFeeDiscountPrice | currency }}원</span>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</td>
							</tr>
							<tr v-for="row in item.optionItemSelected" :key="row.id">
								<td class="tit">
									<span class="txt">{{ row.displaySectionModel.displayName }}</span>
								</td>
								<td class="amount">
									<span class="txt">{{ row.orderCount }}</span>
								</td>
								<td class="price">
									<span class="txt">{{ row.finalPrice | currency }}원</span>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<!--//order item-->
			</div>
			<!--//scroll area-->

			<!--@position :fixed bottom area-->
			<div class="aside-footer">
				<!--@order summary-->
				<div class="contents" v-if="this.$store.getters.getBasicItemSelected.length > 0">
					<table class="summary">
						<tbody>
							<tr class="total">
								<td class="tit"><span class="txt">상품 금액</span></td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totOriginPrice | currency }}원</span>
								</td>
							</tr>
							<tr class="discount">
								<td class="tit">
									<span class="txt">할인 금액</span>
									<span class="">
										<span class="ic-16 ic-info">
											<span class="tooltip-layer right">
												12개월 이상 선납 할인 또는 프로모션으로 혜택 받은 금액입니다.
											</span>
										</span>
									</span>
								</td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totDiscountPrice | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="tit">
									<span class="txt">상품 금액 - 할인 금액</span>
								</td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.totPayPrice | currency }}원</span>
								</td>
							</tr>
							<tr>
								<td class="tit"><span class="txt">부가세(10%)</span></td>
								<td class="price">
									<span class="txt">{{ this.$store.getters.getSummuryPrices.surtaxPrice | currency }}원</span>
								</td>
							</tr>

							<tr
								class="balance align-middle"
								@click="
									() => {
										this.openSideBar = 'open';
									}
								"
							>
								<td class="tit">
									<span class="txt">총 금액<em>(VAT포함)</em></span>
								</td>
								<td class="price">
									<span class="txt"
										>{{ this.$store.getters.getSummuryPrices.finalPayPrice | currency }}<em>원</em></span
									>
								</td>
							</tr>
						</tbody>
					</table>
					<div class="btn-area">
						<template v-if="type == 'NEW'">
							<template v-if="isServiceTypeSAAS() && !isNormalAccount">
								<button class="solid full primary" v-on:click="applyService()">
									<span class="txt">서비스 신청</span>
								</button>
							</template>
							<template v-if="this.$store.getters.getSummuryPrices.finalPayPrice > 0">
								<button class="line full" v-on:click="applyEstimate()">
									<span class="txt">견적서 받기</span>
								</button>
							</template>
						</template>
					</div>
					<div class="additional-info" v-if="serviceType === 'VPC'">
						<i class="ic-24 ic-notice"></i>
						<p>
							단독형은 온라인 주문이 불가능합니다. <br />
							추가/연장 주문이 필요하실 경우 다우오피스 영업 담당자에게 문의하시기 바랍니다.
							<br />
							1577-3019(내선 2번)
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { product } from '@/views/mixinsProduct.js';
import mixinsAlert from '@/components/common/mixinsAlert';
export default {
	mixins: [product, mixinsAlert],
	data() {
		return {
			baseFoldFlag: {},
			addonFoldFlag: {},
			columns: ['구간', '가격', '할인수', '할인', '할인 금액'],
			top: '50px',
			showModal: false,
			modalName: `sideBar`,
			openSideBar: '',
		};
	},
	props: {
		goApplyService: {
			type: Boolean,
			default: false,
			required: false,
		},
		type: {
			type: String,
			default: 'NEW',
		},
		usingMembership: {
			type: Object,
			default: null,
		},
		serviceType: {
			type: String,
			default: 'SAAS',
		},
	},
	watch: {
		goApplyService() {
			if (this.goApplyService) {
				this.applyService();
			}
		},
	},
	computed: {
		// computedTop: function () {
		// 	if (this.type == 'NEW') {
		// 		require('@/assets/css/doms_dev_custom.css');
		// 	}
		// 	return this.top;
		// },
		isNormalAccount() {
			return this.$store.getters['isNormal'];
		},
	},
	methods: {
		getTotQuota(addUserCnt, unitQuota) {
			// 상단 노출될 용량 계산
			if (!addUserCnt) {
				return 0;
			}
			if (!unitQuota) {
				return 0;
			}
			return parseInt(addUserCnt) * parseInt(unitQuota);
		},
		shorteningWords(str) {
			// 상품명 줄임 처리(10자 이상 말줄임)
			if (str.length > 20) {
				return str.substr(0, 10) + '...';
			}
			return str;
		},
		async checkVirtualDomain(domain) {
			// 가상 도메인 유효성체크
			// 1. 길이 조건 체크
			if (domain.length < 3 || domain.length > 64) {
				return '길이 조건(3자리 이상 64자리 이하) 미충족';
			}
			// 2. 문자열 체크
			var domainTest = domain;
			var pattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (pattern.test(domainTest.replace(/[\\_.-]/g, ''))) {
				return '유효하지 않는 형식';
			}
			// 3. 중복/사용불가문자 등 API 조회 체크
			const response = await this.$store.dispatch('checkAdmin', domain);
			return response.data ? response.data : response.errorDescription;
		},
		toggleFold(type, typeDtl, itemId) {
			// 영역접기/펼치기
			this.$store.commit('TOGGLE_FOLD', {
				type: type, // base, addon
				typeDtl: typeDtl, // defaultFee, membershipFee, addUserFee
				itemId: itemId, // (부가일때)아이디
			});
		},
		openModal(item) {
			// 사용자 구간 모달 OPEN
			this.$emit('openModal', item);
		},
		async applyService() {
			// [서비스신청] 클릭
			//1. 사용자 수 체크
			if (!this.$store.getters.getBasicItemSelected[0].userCnt) {
				this.alert({
					title: '사용자 수 미입력',
					contents: '사용자 수를 입력해주세요.',
				});
				return;
			}
			if (
				this.$store.getters.getBasicItemSelected[0].userCnt <
				this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum
			) {
				this.alert({
					title: '최소 사용자 수 제한',
					contents:
						'사용자수는 최소 ' +
						this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum +
						'명 이상 입력하셔야 합니다.',
				});
				return;
			}
			if (this.$store.getters.getBasicItemSelected[0].serviceCategoryModel.serviceType == 'SAAS') {
				// 2. 가상도메인 체크
				const options = this.$store.getters.getBasicItemSelected[0].optionItemSelected;

				const virtualDomain = options.filter((option) => option.itemType == 'VIRTUAL_DOMAIN');
				if (virtualDomain.length > 0) {
					if (virtualDomain[0].orderCount > virtualDomain[0].domainText.length) {
						this.alert({
							title: '가상도메인 미입력',
							contents: '사용하고자 하는 가상도메인의 주소 ' + virtualDomain[0].orderCount + '개 를 작성해주세요.',
						});
						return;
					} else {
						let dupleFlag = false;
						await virtualDomain[0].domainText.forEach((text) => {
							if (virtualDomain[0].domainText.filter((obj) => obj == text).length > 1) {
								dupleFlag = true;
							}
						});

						if (dupleFlag) {
							this.alert({
								title: '가상도메인 주소 중복',
								contents: '중복된 가상도메인 주소가 있습니다. 확인해주세요.',
							});
							return;
						}

						for (let i = 0; i < virtualDomain[0].domainText.length; i++) {
							if (!virtualDomain[0].domainText[i]) {
								this.alert({
									title: '가상도메인 ' + (i + 1) + ' 미입력',
									contents: '가상도메인 ' + (i + 1) + '가 입력되지 않았습니다. 확인해주세요.',
								});
								return;
							} else {
								const res = await this.checkVirtualDomain(virtualDomain[0].domainText[i]);
								if (res !== 'VALID_DOMAIN') {
									this.alert({
										title: '가상도메인 ' + (i + 1) + ' 오류',
										contents: '가상도메인 ' + (i + 1) + '(은)는 ' + res,
									});
									return;
								}
							}
						}
					}
				}

				this.$store.commit('SET_USE_PERIOD', this.$store.getters.getBasicItemSelected[0].usePeriod);
				this.$router.push(`/apply/service/step1`);
			} else {
				// 발생하면 안됨.
				this.alert({
					title: '온라인 신청 불가',
					contents: '온라인 서비스 신청이 불가합니다.<br /> 영업 담당자나 1577-3019 으로 문의해주시기 바랍니다',
				});
				return;
			}
		},
		applyEstimate() {
			if (
				this.$store.getters.getBasicItemSelected[0].userCnt <
				this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum
			) {
				this.alert({
					title: '최소 사용자 수 제한',
					contents:
						'사용자수는 최소 ' +
						this.$store.getters.getBasicItemSelected[0].addUserIntervalPolicyModel.minimum +
						'명 이상 입력하셔야 합니다.',
				});
				return;
			}
			// [견적서 받기] 선택
			this.$router.push(`/apply/estimate/step1`);
		},
		isServiceTypeSAAS() {
			return this.$store.getters.getBasicItemSelected[0].serviceCategoryModel.serviceType == 'SAAS';
		},
	},
	components: {},
};
</script>
